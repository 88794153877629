<template>
    <div>
        <van-cell-group title="登录引导">
            <van-cell :value="guide">

            </van-cell>

        </van-cell-group>

    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            guide:'请在旺财私信窗口中,输入 #下单链接# 即可获得专用链接,切勿将链接发送给他人,以避免不必要的麻烦'
        }
    }
}
</script>

<style scoped>

</style>